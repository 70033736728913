import packageJson from "../../package.json";
//import {$i18n} from "./i18n.js";

export class Options
{
    static brandName = "Books Samael Aun Weor";
    static brandEmail = "books.saw@gmail.com";
    static brandPhone = "+1 (418) 957-3284"

    static linkEbookTutorial = '';
    
    static appName(){ return Options.brandName; }
    
    static appVersion(){ return packageJson.version; }

    static homepage(){ 
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return "http://localhost:3000";
        } 
        else {
            return packageJson.homepage;
        }
    }
    
    static appTitle(){
        return this.appName();
    }

    static versionHistory = [
        {version: "0.1.1", description: "", timestamp: '2019-11-02'},
    ]

    static getBackendURL(){
        if(process.env.NODE_ENV === "development"){
            return `${window.location.protocol}//${window.location.hostname}/bookstorebackend/backend/`;
        }
        else{
            //return window.location.origin + "/sas2-backend/";
            //return `${window.location.protocol}//${window.location.hostname}/bookstorebackend/backend/`
            return `https://books.gnosis.ca/backend/`;
        }
    }

    static getPayPalClientId(){
        if(process.env.NODE_ENV === "development"){
            return "AZmLSeapT1uZsEJKeyU0-iTZONEtOPQ8dvws4da7NF0MPLbSD2ZaZaW-PuqKOBWQjTe4--l0othHy4xD";
        }
        else{
            //return window.location.origin + "/sas2-backend/";
            //return `${window.location.protocol}//${window.location.hostname}/bookstorebackend/backend/`
            return "ASWWKrdDdHQshbryWOA0GMEnao3rHdIVdmXujpP4owpY1OBam6xQ0-ayHhcTEwdexMCo6pDOJU4t5lAU";
        }
    }

    static getGateway(){
        return `${Options.getBackendURL()}online-store/index.php`;
    }

    static getDataURL(){
        return `${Options.getBackendURL()}data/`;
    }
    
    static nbDaysRelease = 90;
}